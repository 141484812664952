import {createApp} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import i18n from "./i18n";
import defaultImage from "../public/favicon.png";
import VueCookies from "vue-cookies";
import VueCookieComply from "vue-cookie-comply";
import "vue-cookie-comply/dist/style.css";
import Vue3Progress from "vue3-progress";

fetch('/config.json').then(res => res.json()).then(config => {
  
  /**
   * Obtener el fichero de config en /public/config.json
   * Una vez tenemos el json de config, creamos la app y asignamos config a una variable global 'owconfig'
   * Luego se tiene que utilizar la variable global 'owconfig' en el servicio de axios
   */

  let app = createApp(App);

  app.config.globalProperties.owconfig = config;
  // console.log(config, app.config.globalProperties.owconfig)

  const options = {
    position: "fixed",
    height: "4px",
    color: "#818181",
  };

  // global default image
  app.config.globalProperties.defaultImageApp = defaultImage;

  app
    .use(VueCookies)
    .use(VueCookieComply)
    .use(i18n)
    .use(store)
    .use(Quasar, quasarUserOptions)
    .use(router)
    .use(Vue3Progress, options)
    .mount("#app");

  app.$cookies.config("7d");
}).catch(err => console.log('NOT CONFIG FOUND', err));



