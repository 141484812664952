import store from "../../store"

function guestMiddleware(to, from, next) {
    if (store.state.auth.authenticated) {
        next({ name: "Home" });
    }
    next();
}

function authMiddleware(to, from, next) {
    if (store.state.auth.authenticated) {
        next();
    } else {
        next({ name: "Login" });
    }
}

export { guestMiddleware, authMiddleware }