export default {
  "General": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo attuale"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambiare"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquistare"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovata. Per favore, torna alla Home o contattaci tramite la nostra email"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotti"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pannello"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafoglio"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendere un momento..."])},
    "problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore imprevisto"])},
    "mail-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utente", "@", "esempio.com"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti disponibili"])},
    "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono dati disponibili"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullare"])},
    "add_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta di punti"])},
    "sub_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottrazione di punti"])}
  },
  "Login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account"])},
    "your-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua email"])},
    "your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "repeat-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la tua password"])},
    "login-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi ora"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai un account?"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account"])},
    "privacy-politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica sulla privacy"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande frequenti"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova"])},
    "legal-disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso legale"])},
    "wrong-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREDENZIALI ERRATE"])},
    "wrong-credentials-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email o password non validi"])},
    "wrong-credentials-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile effettuare la registrazione"])},
    "starting-session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvio della sessione in corso..."])},
    "go-back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo nome"])},
    "introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo cognome"])},
    "user-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente creato con successo"])},
    "user-not-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, non è stato possibile creare l'utente"])},
    "error-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati che le password coincidano o utilizza un'altra email"])},
    "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci un'email valida"])},
    "accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto le politiche sulla privacy"])},
    "accept-policy-text-part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto le "])},
    "accept-policy-link-part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politiche sulla privacy"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "please-accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, accetta le politiche sulla privacy per continuare"])},
    "web-omniwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi accedere al sito web di Omniwallet?"])},
    "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password?"])},
    "web-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "go-back-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna indietro"])},
    "get-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera password"])},
    "introduce-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la tua email attuale di"])},
    "send-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti invieremo un'email per recuperare la tua password"])},
    "current-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password attuale"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia password"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma nuova password"])},
    "success-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password è stata cambiata con successo"])},
    "problem-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati che la tua email sia corretta e che le password coincidano."])},
    "problem-password-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati che la tua email sia corretta."])},
    "get-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per ottenere un link di recupero"])},
    "apply-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi"])},
    "check-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla l'email per recuperare la tua password"])},
    "session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessione scaduta"])},
    "session_expired_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua nuovamente l'accesso"])},
    "SignUp": {
      "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo nome"])},
      "your-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo cognome"])},
      "your-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo telefono"])},
      "min-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli una password più sicura. Usa una combinazione di lettere e numeri"])},
      "min-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo telefono deve contenere almeno 9 caratteri"])},
      "wrong-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data non valida"])}
    }
  },
  "Profile": {
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio profilo"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
    "success-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni modificate con successo"])},
    "fail-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, non siamo riusciti a modificare le tue informazioni"])},
    "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla home"])},
    "edit-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica di nuovo"])},
    "personal-address": {
      "my-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei indirizzi"])},
      "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi di spedizione"])},
      "billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi di fatturazione"])},
      "add-shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo indirizzo di spedizione"])},
      "add-billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo indirizzo di fatturazione"])},
      "please-introduce-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci un indirizzo"])},
      "please-introduce-postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo codice postale"])},
      "please-introduce-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la tua città"])},
      "please-introduce-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la tua provincia"])},
      "please-introduce-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo paese"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
      "confirm-delete-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi eliminare questo indirizzo?"])}
    },
    "personal-data": {
      "my-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati personali"])},
      "please-introduce-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la tua email"])},
      "please-introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo nome"])},
      "please-introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo cognome"])},
      "please-introduce-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci il tuo telefono"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])}
    },
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscire"])},
    "logout-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnessione in corso..."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
    "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingue"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, aspetta un momento..."])}
  },
  "Home": {
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogo"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutti"])},
    "last-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi movimenti"])},
    "see-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi movimenti"])}
  },
  "ControlPanel": {
    "control-panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pannello di controllo"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafoglio"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mia carta"])},
    "hello-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciao"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto nel pannello di controllo del tuo account"])},
    "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da qui puoi visualizzare il tuo saldo disponibile, il tuo indirizzo predefinito e il codice con il numero della tua carta fedeltà."])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº di carta"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "download-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica la tua carta"])},
    "info-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora puoi iniziare ad accumulare e riscattare punti. Inoltre, sarai il primo a sapere di vendite, offerte speciali ed eventi esclusivi"])}
  },
  "BuyingProcess": {
    "transactions": {
      "past-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni passate"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese"])},
      "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate"])},
      "transaction-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº ordine"])},
      "transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'ordine"])},
      "transaction-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato dell'ordine"])},
      "transaction-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'ordine"])},
      "transaction-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine riscattato"])},
      "transaction-reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº riferimento"])},
      "manage-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci il mio ordine"])},
      "continue-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua a comprare"])},
      "processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborando il tuo ordine"])},
      "no-processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci dispiace, non possiamo riscattare il tuo ordine"])},
      "no-address-chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo selezionato"])},
      "add-new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo indirizzo"])},
      "use-same-address-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa lo stesso indirizzo per la fatturazione"])},
      "add-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un commento"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
      "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla Home"])}
    },
    "products": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità"])},
      "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
      "redeemed-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto riscattato"])},
      "download-coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il tuo coupon in formato PDF"])},
      "lack-of-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti mancano"])},
      "lack-of-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punti per riscattare questo prodotto."])},
      "want-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo voglio"])},
      "stock1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponibile"])},
      "stock2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultime unità"])},
      "stock3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile"])},
      "available-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi"])},
      "available-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibili"])},
      "buy-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma d'acquisto"])},
      "total-expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo totale"])},
      "final-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo finale"])},
      "home-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna a domicilio"])},
      "pickup-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro in negozio"])},
      "extra-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'opzione di consegna a domicilio ha un costo extra di"])},
      "please-choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, scegli un'opzione"])},
      "please-choose-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, specifica un indirizzo"])},
      "send-to-my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voglio inviare il mio prodotto all'indirizzo del mio profilo"])},
      "new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo indirizzo di spedizione"])},
      "my-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo ordine"])},
      "exchanged-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stato riscattato con successo."])},
      "will-get-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverai un'email con i dettagli dell'ordine. Ti aspettiamo!"])},
      "click-manage-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca su 'Gestisci il mio ordine' per scaricare il tuo coupon"])},
      "no-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponibile"])},
      "no-shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono negozi"])},
      "no-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono indirizzi"])},
      "last-units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultime unità"])},
      "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile"])},
      "see-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutti i prodotti"])},
      "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non abbiamo ancora prodotti disponibili"])},
      "no-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai abbastanza punti"])},
      "search-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un prodotto..."])},
      "show-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra solo disponibili"])},
      "available-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra solo con punti sufficienti"])},
      "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tutti"])},
      "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un'opzione"])},
      "filter-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "filter-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
      "filter-stock-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con disponibilità"])},
      "filter-enough-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con punti sufficienti"])},
      "sort-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina"])},
      "sort-a-z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetico: A-Z"])},
      "sort-z-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetico: Z-A"])},
      "sort-cheaper-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo: Dal più basso al più alto"])},
      "sort-expensive-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo: Dal più alto al più basso"])},
      "join-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti al nostro club e inizia a vincere premi"])}
    }
  },
  "FAQ": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande frequenti (FAQ)"])},
    "title-about-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande sul catalogo"])},
    "title-about-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso e password"])},
    "title-about-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti"])},
    "app-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove posso scaricare il catalogo?"])},
    "app-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quanto costa il catalogo?"])},
    "app-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa include il catalogo di Omniwallet?"])},
    "app-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non riesco a caricare il catalogo o i prodotti al suo interno. Cosa è successo?"])},
    "app-question-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali dispositivi possono utilizzare il catalogo?"])},
    "app-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il catalogo Omniwallet può essere scaricato dal browser del cellulare. Su alcuni dispositivi il messaggio di download appare nel browser stesso, su altri può essere trovato nelle impostazioni."])},
    "app-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il catalogo Omniwallet è completamente gratuito."])},
    "app-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il catalogo offre diverse opzioni al cliente. Permette di riscattare prodotti con punti, consultare transazioni passate e apportare modifiche al profilo."])},
    "app-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innanzitutto, controlla la connessione a Internet. Se l'errore persiste, esci e accedi di nuovo."])},
    "app-answer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il catalogo è attualmente disponibile su iOS e Android."])},
    "login-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come accedo?"])},
    "login-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come esco?"])},
    "login-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho perso il mio utente e la mia password"])},
    "login-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non riesco a scaricare le informazioni. Cosa è successo?"])},
    "login-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserisci la tua email e password. La password predefinita è il numero della carta assegnato nell'email. Se ti sei registrato tramite il catalogo, la password è quella indicata nel modulo."])},
    "login-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al profilo e nella sezione “Privacy” clicca su “Esci”."])},
    "login-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, contatta omniwallet", "@", "omniwallet.net."])},
    "login-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per accedere al catalogo, utilizza la stessa email e password associati al tuo account. In caso di problemi, esci. Se il problema persiste, contatta il servizio clienti omniwallet", "@", "omniwallet.net."])},
    "buying-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come posso acquistare un prodotto?"])},
    "buying-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come posso visualizzare le informazioni di una transazione passata?"])},
    "buying-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come posso modificare il mio profilo?"])},
    "buying-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come posso consultare il mio numero di carta?"])},
    "buying-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segui questi passaggi: Vai alla pagina principale o alla pagina dei prodotti. Poi, clicca su un prodotto. Se è disponibile e hai abbastanza punti, clicca su Riscatta. Successivamente, specifica la destinazione del prodotto: ritiro in negozio o consegna a domicilio. Se scegli l'opzione di consegna a domicilio, puoi aggiungere un nuovo indirizzo. Infine, clicca su Acquista e procedi con la transazione."])},
    "buying-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla pagina delle transazioni e clicca su una qualsiasi. Puoi consultare sia le spese che gli ingressi."])},
    "buying-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla pagina del profilo e clicca sull'icona della matita."])},
    "buying-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al pannello di controllo e lì potrai vedere il tuo numero di carta."])}
  }
}