<template>
  <router-view />
  <vue3-progress />
  <vue-cookie-comply
    :style="{ 'font-family': settings.typography }"
    @on-accept-all-cookies="onAcceptAll"
    class="fixed-bottom q-ma-sm"
    style="z-index: 3"
  >
    <template v-slot:header>
      <header class="text-weight-bold">Política de Cookies</header>
      <p>
        Usamos cookies propias para mejorar nuestros servicios. Si continua
        navegando entendemos que acepta su uso. Consulte también nuestra
        política de privacidad.
      </p>
    </template>
  </vue-cookie-comply>

  <!-- Session expired dialog -->
  <SessionExpiredWarning :settings="settings" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import progress from "@/mixins/progress";
import update from "@/mixins/update";
import settings from "@/mixins/settings";
import language from "@/mixins/language";
import customer from "@/mixins/customer";

const SessionExpiredWarning = defineAsyncComponent(() =>
  import("../src/modules/module-1-auth/components/SessionExpiredWarning.vue")
);

export default {
  mixins: [update, progress, settings, language, customer],
  components: {
    SessionExpiredWarning,
  },
  props: {
    onAcceptAll: 0,
  },
  mounted() {
    this.redirectToHttps();
    this.setAppColors();
  },
  watch: {
      settings: function (val) {
        this.setAppColors();
      },
  },
  methods: {
    // Actualización si hay cambios
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      this.refreshApp();
    },
    redirectToHttps() {
      if (location.protocol !== 'https:') {
        location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      }
    },
    setAppColors() {
      document.documentElement.style.setProperty('--elements-color-primary', this.settings.color_primary);
      document.documentElement.style.setProperty('--elements-color-secondary', this.settings.color_accent);
      document.documentElement.style.setProperty('--bg-color-primary', this.settings.bg_color_primary);
      document.documentElement.style.setProperty('--bg-color-secondary', this.settings.bg_color_secondary);
      document.documentElement.style.setProperty('--nav-color-primary', this.settings.nav_color_primary);
      document.documentElement.style.setProperty('--nav-color-secondary', this.settings.nav_color_secondary);
      document.documentElement.style.setProperty('--card-color-primary', this.settings.card_color_primary);
      document.documentElement.style.setProperty('--card-color-secondary', this.settings.card_color_secondary);
      document.documentElement.style.setProperty('--font-color-primary', this.settings.font_color_primary);
      document.documentElement.style.setProperty('--font-color-secondary', this.settings.font_color_secondary);
    }
  },
};
</script>

<style lang="scss">
@import "~quasar-variables";
@import "styles/fonts";
@import "styles/styles";
@import "styles/darkmode";

#app {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  // color: #282828;
  text-align: center;
  background: transparent;
  font-family: v-bind("settings.typography");
  height: 100vh;
}

body {
  font-family: v-bind("settings.typography");
  // background-color: #fff;
  // color: $dark;
  transition: background-color 0.2s ease, color 0.2s ease;
}

* {
  font-family: v-bind("settings.typography");
}

.q-dialog {
  font-family: v-bind("settings.typography");
}

.container-update {
  border-radius: 25px 25px 25px 0px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
  background-color: v-bind("settings.color_primary");
  .q-btn {
    background-color: v-bind("settings.color_accent");
    border-radius: 25px 25px 25px 0px;
  }
}

.button {
  background-color: v-bind("settings.color_accent");
}

.cookie-comply__button {
  display: none;
}

.cookie-comply__button-accept {
  display: block !important;
  float: right !important;
  background-color: v-bind("settings.color_primary") !important;
}
</style>
