import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions({ setUser: "auth/setUser" }),
  },
  mounted() {
    this.setUser();
  },
};
