import axios from "../../../services/Api";
import router from "../../../router/index";
import store from "../../../store";
import i18n from "@/i18n";


// Comitea la acción del login
export const login = async ({ commit }) => {
  await axios.get("/front-catalog/customers").then(async ({ data }) => {
    commit("SET_USER", data);
    commit("SET_AUTHENTICATED", true);

    // Update i18n locale
    i18n.global.locale = data.locale == "ca" ? "cat" : data.locale;

    // Fetch all store data to avoid catalog appearing empty right after login redirect
    const pointsPromise = store._actions['points/getPoints'][0]();
    const ordersPromise = store._actions['orders/getOrders'][0]();
    const productsPromise = store._actions['products/getProducts'][0]();
    const shopsPromise = store._actions['shops/getShops'][0]();
    const transactionsPromise = store._actions['transactions/getTransactions'][0]();
    const addressesPromise = store._actions['addresses/getAddresses'][0]();

    Promise.all([pointsPromise, ordersPromise, productsPromise, shopsPromise, transactionsPromise, addressesPromise])
      .then(() => {
        router.push({ name: "Home" });
      })
      .catch(() => {
        router.push({ name: "Home" });
      });
  });
};

// Comitea la acción del logout
export const logout = async ({ commit }) => {
  await commit("SET_USER", {});
  commit("SET_AUTHENTICATED", false);
};

// Recupera información del perfil de usuario una vez hechos cambios 
export const setUser = async ({ commit }) => {
  await axios.get("/front-catalog/customers").then(({ data }) => {
    commit("SET_USER", data);
  });
};
