import Api from "../Api";
import i18n from "../../i18n";
import { getApiLocale } from "../helpers/Api";

export default {
  async allCoupons() {
    try {
      const fetchProductsUrl =
        i18n.global.locale === "es"
          ? "front-catalog/chequemotiva/coupons"
          : `front-catalog/${getApiLocale()}/chequemotiva/coupons`;

      const fetchCoupons = await Api.get(fetchProductsUrl);
      return fetchCoupons;
    } catch (error) {
      if (error.response.status !== 403) { // Forbidden is an expected error when tenant is not allowed to use coupons, no need to log it
        console.log(error);
      }
    }
  },
};
