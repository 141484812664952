import i18n from "../../i18n";

const getApiLocale = () => {
    let apiLocal = i18n.global.locale;
    if (i18n.global.locale === 'cat') {
        apiLocal = 'ca'
    }
    return apiLocal;
}

export { getApiLocale };