import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";
import config from "../../public/config.json"
import { guestMiddleware, authMiddleware } from "../services/helpers/Middleware";


const routes = [
  // MODULO 1 Login & Sign Up

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "Login" */ "../modules/module-1-auth/views/Login.vue"
      ),
    meta: {
      middleware: ["guest"],
      title: "Login",
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () =>
      import(
        /* webpackChunkName: "SignUp" */ "../modules/module-1-auth/views/SignUp.vue"
      ),
    meta: {
      middleware: ["guest"],
      title: `Registro`,
    },
  },
  {
    path: "/auth",
    name: "TokenLogin",
    component: () =>
      import(
        /* webpackChunkName: "Token Login" */ "../modules/module-1-auth/views/TokenLogin.vue"
      ),
    meta: {
      middleware: [],
      title: "Login",
    },
  },

  {
    path: "/politica-privacidad",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */ "../modules/module-1-auth/views/PrivacyPolicy.vue"
      ),
    meta: {
      title: "Política de privacidad",
      middleware: ["guest"],
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../modules/module-2-main-pages/components/profile/privacy/ForgotPassword"
      ),
    meta: {
      title: "Forgot password",
      middleware: ["guest"],
    },
  },
  {
    path: '/reset-password', 
    name: "reset-password",
    component: () =>
    import(
      /* webpackChunkName: "ResetPassword" */ "../modules/module-2-main-pages/components/profile/privacy/ResetPassword"
    ),
    meta: {
      title: "Reset password",
      middleware: ["guest"],
    },
  },

  // MODULO 2 Main pages - Home, Profile, Control Panel, Products

  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "../modules/module-2-main-pages/views/Home.vue"
      ),
    meta: {
      middleware: ["auth"],
      title: "Home",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "../modules/module-2-main-pages/views/Profile.vue"
      ),
    meta: {
      middleware: ["auth"],
      title: "Perfil",
    },
  },

  {
    path: "/controlpanel",
    name: "ControlPanel",
    component: () =>
      import(
        /* webpackChunkName: "Control panel" */ "../modules/module-2-main-pages/views/ControlPanel.vue"
      ),
    meta: {
      middleware: ["auth"],
      title: "Panel de control",
    },
  },

  // MODULO 3 Transactions

  {
    path: "/products",
    name: "Products",
    component: () =>
      import(
        /* webpackChunkName: "NewProducts" */ "../modules/module-3-transactions/views/Products.vue"
      ),
    meta: {
      middleware: ["auth"],
      title: "Productos",
    },
  },
  {
    path: "/preview/products",
    name: "Preview Products",
    component: () =>
      import(
        /* webpackChunkName: "NewProducts" */ "../modules/module-3-transactions/views/PreviewProducts.vue"
      ),
    meta: {
      middleware: ["guest"],
      title: "Productos",
    },
  },
  {
    path: "/transacciones",
    name: "Transacciones",
    component: () =>
      import(
        /* webpackChunkName: "Transacciones" */ "../modules/module-3-transactions/views/PastTransactions.vue"
      ),
    meta: {
      middleware: ["auth"],
      title: "Transacciones",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(
        /* webpackChunkName: "NoPageFound" */ "../modules/shared/views/NoPageFound"
      ),
    meta: {
      middleware: ["auth"],
      title: "Página no encontrada",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(config.api.url),
  routes,
});

// funcion que permite acceder según permiso

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;

  to.meta.middleware.forEach((middleware) => {
    switch (middleware) {
      case "guest":
        guestMiddleware(to, from, next);
        break;
      case "auth":
        authMiddleware(to, from, next);
        break;
    }
  });
  next();
});

export default router;
