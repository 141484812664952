import Api from "../Api";
import i18n from "../../i18n";
import Coupons from "./Coupons"
import { getApiLocale } from "../helpers/Api";
import { formatDateDMY } from "../helpers/Date";

function mapCouponToProductFormat(coupon) {
  return {
      id: coupon.id,
      name: coupon.name,
      sku: coupon.id,
      stock: 100, // Always in stock if enabled
      type: 1, // Digital type
      brand: coupon.brand,
      description: coupon.description || '',
      value: coupon.points,
      //images: [coupon.thumbnail],
      images:[coupon.thumbnail],
      visible: 1,
      category_id: coupon.category,
      options: { attributes: null },
      isCoupon: true,
      couponData: coupon,
      expiration: formatDateDMY(new Date(coupon.expiration * 1000)),
  };
}

export default {
  async allProducts() {
    try {
      const fetchProductsUrl =
        i18n.global.locale === "es"
          ? "front-catalog/products"
          : `front-catalog/${getApiLocale()}/products`;

      // Fetch products from the API
      let fetchProducts = await Api.get(fetchProductsUrl);

      // Get coupons
      try { // try/catch in case endpoint returns 403 forbidden because tenant is not allowed to use coupons
        const coupons = (await Coupons.allCoupons()).data;
      
        // Map and merge the coupons into the product list
        const mappedCoupons = coupons.map((coupon) => mapCouponToProductFormat(coupon));
        fetchProducts.data = fetchProducts.data.concat(mappedCoupons);
      } catch {
        // Do nothing
      }
      
      
      return fetchProducts;
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error; // Optionally propagate the error for further handling
    }
  },
};
