import axios from "axios";
import config from "../../public/config.json"
import router from "../../src/router";
import store from "../../src/store";

const ApiOmniwallet = axios.create({
    withCredentials: true,
    timeout: 30000,
    headers: {
        
    }
});

ApiOmniwallet.interceptors.request.use(function (axiosConfig) {
    if (config.api.dev_mode === false) {
        axiosConfig.baseURL = `https://dashboard.${window.location.host.split('.')[1]}.net`; // We use the catalog domain to decide wether to call the production or staging backend
        const subdomain = window.location.host.split('.')[0]
        axiosConfig.headers['X-Omniwallet-Account'] = subdomain // The catalog subdomain is used to identify the Omniwallet account
    } else {
        axiosConfig.baseURL = config.api.url;
        axiosConfig.headers['X-Omniwallet-Account'] = config.api.dev_header;
        axiosConfig.headers.Authorization = `Bearer ${config.api.dev_token}`;
    }

    return axiosConfig;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

ApiOmniwallet.interceptors.response.use((response) => {
    // Do nothing
    return response
  }, (error) => {
    // Extra measure to redirect user to login in case session gets invalidated in server
    // but local state (stored in Pinia store) thinks the user is still logged in
    logoutIfUnauthorized(error)

    return Promise.reject(error)
  })

async function logoutIfUnauthorized(error) {
    const isGuestMode = !store.state.auth.authenticated;
    const hasAuthMiddlware = router.currentRoute.value.meta.middleware.includes('auth');
    if (
        error.response.status === 401
        && !isGuestMode
        && hasAuthMiddlware
    ) {
        router.push({ query: { session_expired: null } });
    }
}

export default ApiOmniwallet;
